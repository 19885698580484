.card-purchase-order .card-title {
  font-size: 1.1rem;
}[dir] .card-purchase-order .card-title {
  margin-bottom: 0px !important;
}
[dir] .card-purchase-order .card-header {
  padding: 10px 20px !important;
}
.selected-items {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0px;
}
[dir] .selected-items {
  margin-top: 10px;
}
[dir=ltr] .selected-items {
  left: 10px;
}
[dir=rtl] .selected-items {
  right: 10px;
}
.chip {
  display: inline-flex;
  align-items: center;
  color: #f1f1f1;
  font-size: 0.8rem;
}
[dir] .chip {
  background-color: #0875d6;
  border-radius: 16px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
[dir=ltr] .chip {
  margin-right: 5px;
}
[dir=rtl] .chip {
  margin-left: 5px;
}
.v-select-plans .vs__clear {
  display: block !important;
}